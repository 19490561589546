// EnvironmentContext.js
import React, { useState, useEffect, createContext } from 'react';
import axios from 'axios';

export const EnvironmentContextObject = createContext(null);

const EnvironmentContext = ({ children }) => {
  const [envData, setEnvData] = useState({"auth_client_id": "none"});
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const hostname = window.location.hostname;
  let port = window.location.port ? `:${window.location.port}` : '';
  if (port === ":3000") { // For local env support
    port = ":8008";
    console.log('Local environment detected. Using port 8008 for API calls.');
  }
  const baseApiUrl = process.env.REACT_APP_AUTH_SERVICE_API_BASE_URL || `${window.location.protocol}//${hostname}${port}/api/v1/`;

  const handleEnvData = (newData) => {
    setEnvData(newData);
    console.log('Environment state data:', envData);
    setIsLoading(false);
  };

  useEffect(() => {
    try {
      axios.get(`${baseApiUrl}info/env`, {
      }).then(response => {
          const { data } = response;
          console.log('Environment data:', data);
          handleEnvData({ ...data });
      }).catch(error => {
          console.error('Error getting the environment information:', error);
          setIsLoading(false);
      });
    } catch (error) {
        console.error('Environment context acquisition failed:', error);
        setIsLoading(false);
    }
  }, []);

  // Show loading state while fetching data
  if (isLoading) {
    return <div>Loading environment...</div>; // TODO: Consider replacing this with something more appripriate for our UX
  }

  return (
    <EnvironmentContextObject.Provider value={envData}>
      {children}
    </EnvironmentContextObject.Provider>
  );
};

export default EnvironmentContext;
