import React, { useContext, useState, useEffect, useRef } from 'react';
import FileContext from '../FileContext';
import Spinner from './Spinner';
import axios from 'axios';
import ChatRegenerateInput from './ChatRegenerateInput';
import './styles/Chat.css';
import {marked} from 'marked';
import DOMPurify from 'dompurify';
import{ jwtDecode } from 'jwt-decode';
import { useToken } from './useToken';

const Chat = ({ addMessage, setIsLoading, messages, isLoading, iaRole }) => {
    const { files } = useContext(FileContext);    
    const reversedMessages = messages.slice().reverse();
    const chatContainerRef = useRef(null);
    const tokenData = useToken();
    const token = tokenData.token;
    const userId = tokenData.userId.toString();

    const handleSaveResponse = (dataToSave) => {
        const hostname = window.location.hostname;
        const port = window.location.port ? `:${window.location.port}` : '';
        const baseUrl = process.env.REACT_APP_CONVERSATION_SERVICE_API_BASE_URL || `${window.location.protocol}//${hostname}${port}/api/v1/`;
        setIsLoading(true);
        if (iaRole === "Workstream") {
            axios.post(baseUrl + 'saveWorkstream', {
                workstreamData: JSON.stringify(dataToSave),
                user_id: userId
            }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
            })
                .then(response => {
                    // console.log('Workstream Saved');
                    addMessage({ sender: `AI-CFM`, text: 'Workstream Saved', showSaveButton: false, dataToSave: null , id: Date.now()});   
                    scrollToBottom();
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Error:', error);
                    addMessage({ sender: `AI-CFM`, text: 'Error creating Workstreams: '+error, showSaveButton: false, dataToSave: null , id: Date.now()});
                    setIsLoading(false);                   
                });
        } else if (iaRole === "epics") {
            axios.post(baseUrl + 'uploadEpicsToJira', {
                epicsData: JSON.stringify(dataToSave),
                jira_url : localStorage.getItem('jiraUrl'),
                jira_user : localStorage.getItem('jiraUser'),
                jira_token : localStorage.getItem('jiraToken'),
                project_key : localStorage.getItem('projectKey')
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
                })
                .then(response => {
                    console.log('Epics Uploaded');
                    addMessage({ sender: `AI-CFM`, text: 'Epics uploaded successfully', showSaveButton: false, dataToSave: null , id: Date.now()});  
                    scrollToBottom();
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Error:', error);
                    addMessage({ sender: `AI-CFM`, text: 'Error uploading Epics: '+error, showSaveButton: false, dataToSave: null , id: Date.now()});
                    setIsLoading(false);
                });
        }
    };

    const handleScrollToMessage = (messageId) => {
        const messageElement = document.getElementById(`message-${messageId}`);
        if (messageElement) {
            messageElement.scrollIntoView({ behavior: "smooth" });
        }
    };

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    return (
        <div ref={chatContainerRef} style={{
            display: 'flex',
            flexDirection: 'column-reverse',
            overflow: 'auto',
            position: 'fixed',
            bottom: '65px',
            height: 'calc(100vh - 120px)',
            padding: '10px',
            marginLeft: '250px',
            width: 'calc(100% - 250px)',
            marginTop: '70px'
        }}>
            {isLoading && <Spinner />}
            {reversedMessages.map((message, index) => (
                <div key={index} id={`message-${message.id}`} className={`message ${!message.sender.startsWith('AI') ? 'user-message' : 'ai-message'}`}>
                    <div>
                        <strong>{message.sender}</strong>: {message.text.split('\n').map((line, i) => (
                            <div
                                key={i}
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(marked(line.replace(/^"|"$/g, '').replace(/\\u[\dA-F]{4}/gi, match =>
                                        String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))
                                    )))
                                }}
                            />
                        ))}
                    </div>
                    {message.showSaveButton && (
                        <div>
                            {iaRole === "Workstream" && (
                                <button style={{ marginBottom: '10px' }} onClick={() => handleSaveResponse(message.dataToSave)} disabled={isLoading}>
                                    Save Workstream
                                </button>
                            )}
                            {iaRole === "epics" && (
                                <button style={{ marginBottom: '10px' }} onClick={() => handleSaveResponse(message.dataToSave)} disabled={isLoading}>
                                    Upload Epics
                                </button>
                            )}
                            {message.parentId && (
                                <button style={{ marginLeft: '10px' }} onClick={() => handleScrollToMessage(message.parentId)}>
                                    Scroll to Original
                                </button>
                            )}
                            {iaRole === "Workstream" && (
                                <ChatRegenerateInput addMessage={addMessage} setIsLoading={setIsLoading} isLoading={isLoading} iaRole={"RegenerateWorkstream"} originalMessage={message} disabled={isLoading} />
                            )}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default Chat;