import React from 'react';
import App from './App';
import EnvironmentContext from './Components/EnvironmentContext';

const CtxProviderWrapper = () => {

    return (
        <EnvironmentContext>
            <App />
        </EnvironmentContext>
    );
};

export default CtxProviderWrapper;
