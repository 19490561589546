import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faSpinner, faCheckCircle, faAsterisk, faSquareCaretUp } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useContext, useState, useEffect, useRef } from 'react';
import FileContext from '../FileContext'; // Assuming FileContext is imported from somewhere
import SmartStreamTempLogos from '../images/SmartStreamTempLogos.svg';
import { useToken } from './useToken';

const Sidebar = ({ setShowRequiredFilesModal }) => {
    const { files, deleteFile } = useContext(FileContext);
    const [fileStatuses, setFileStatuses] = useState({});
    const prevFilesRef = useRef([]);
    const prevFileStatusesRef = useRef({});
    const intervalRef = useRef(null);
    const attemptsRef = useRef(0);

    const hostname = window.location.hostname;
    const port = window.location.port ? `:${window.location.port}` : '';
    const baseUrl = process.env.REACT_APP_FILE_SERVICE_API_BASE_URL || `${window.location.protocol}//${hostname}${port}/api/v1/`;
    const embeddingsUrl = process.env.REACT_APP_EMBEDDING_SERVICE_API_BASE_URL || `https://${hostname}/api/v1/embeddings/`;
    const tokenData = useToken();
    const token = tokenData.token;
    const MAX_ATTEMPTS = 8;
    

    const handleOpenRequiredFilesModal = () => {
        setShowRequiredFilesModal(true);
    };

    // Function to check file status
    const checkFileStatus = async (file) => {
        try {
            const response = await axios.get(`${embeddingsUrl}checkEtag/${file.etag}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setFileStatuses(prevStatuses => ({
                ...prevStatuses,
                [file.etag]: response.data.exists
            }));
        } catch (error) {
            console.error('Error checking etag:', error);
        }
    };
    const pollFileStatuses = () => {
        files.forEach(file => {
            // Check if the file's status is missing in fileStatuses
            if (fileStatuses[file.etag] === undefined || fileStatuses[file.etag] === false) {
                checkFileStatus(file);
            }
        });
    };
    // Polling to check the status of files without status
    useEffect(() => {
        if (attemptsRef.current === 0) {
            attemptsRef.current += 1;
            pollFileStatuses();
        }

        if (files.every(file => fileStatuses[file.etag] === true)) {
            return;
        }

        if (!intervalRef.current) {
            intervalRef.current = setInterval(() => {
                if (files.every(file => fileStatuses[file.etag] === true)) {
                    clearInterval(intervalRef.current);
                    intervalRef.current = null;
                    console.log('debug')
                    return;
                }
                attemptsRef.current += 1;
                pollFileStatuses();

                // Stop polling if attempts reach 6 and there are still undefined file statuses
                if (attemptsRef.current >= MAX_ATTEMPTS && files.some(file => fileStatuses[file.etag] === undefined)) {
                    clearInterval(intervalRef.current);
                    intervalRef.current = null;
                }
            }, 5000); // Polling every 5 seconds, adjust as needed
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        };
    }, [files, fileStatuses]);

    return (
        <div>
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '240px',
                height: 'calc(100% - 70px)', // Where 100 is the Footer.js height + button height
                overflow: 'auto',
                backgroundColor: '#E7F4FF',
                padding: '.5em',
                boxSizing: 'border-box',
                borderTopRightRadius: '16px', // Add rounded corners to the top right

            }}>
                <img src={SmartStreamTempLogos} alt="SmartStream Logo" style={{ maxWidth: '210px', paddingBottom: '20px' }} />
                {Array.isArray(files) && files.map((file) => (
                    <div key={file.etag}>
                        <div className="filelisting" title={file.name}>
                            <span style={{ marginRight: '6px' }}>
                                {fileStatuses[file.etag] === undefined ? (
                                    <FontAwesomeIcon icon={faSpinner} spin />
                                ) : fileStatuses[file.etag] ? (
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                ) : (
                                    <FontAwesomeIcon icon={faSpinner} spin />
                                )}
                            </span>
                            <span>
                                {file && file.name
                                    ? (file.name.replace(/_\d+\./, '.').length > 18
                                        ? `${file.name.replace(/_\d+\./, '.').slice(0, 18)}...`
                                        : file.name.replace(/_\d+\./, '.')
                                    ) : ''}
                            </span>
                            <FontAwesomeIcon
                                className="filesDeleteIcon"
                                icon={faTrashAlt}
                                onClick={() => {
                                    const confirmDelete = window.confirm(`Are you sure you want to delete the file: ${file.name}?`);
                                    if (confirmDelete) {
                                        try {
                                            axios.delete(`${baseUrl}files/${file.name}`, {
                                                headers: {
                                                    Authorization: `Bearer ${token}`
                                                }
                                            });
                                            deleteFile(file.name);
                                        } catch (error) {
                                            console.error('Error deleting file:', error);
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div>
                <button className="open-file-list-modal-button" onClick={handleOpenRequiredFilesModal}>
                    <FontAwesomeIcon id="reqFileIcon" icon={faSquareCaretUp} /> Upload Required Files
                </button>
            </div>
        </div>
    );
};

export default Sidebar;
