import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { InputGroup, FormControl } from 'react-bootstrap';
import FileUpload from './FileUpload';
import FileContext from '../FileContext';
import axios from 'axios';
import{ jwtDecode } from 'jwt-decode';
import { useToken } from './useToken';

const ChatRegenerateInput = ({ addMessage, setIsLoading, isLoading, iaRole, originalMessage}) => {
    const [message, setMessage] = useState(''); 
    const { files } = useContext(FileContext);
    const tokenData = useToken();
    const token = tokenData.token;
    const userId = tokenData.userId.toString();
    const userName = tokenData.userName.toString();

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSendMessage = () => {        
        if (isLoading) return;

        addMessage({ sender: userName, text: message, id: Date.now() });
        setIsLoading(true);

        // Get the current hostname
        const hostname = window.location.hostname;
        const port = window.location.port ? `:${window.location.port}` : '';
        const baseUrl = process.env.REACT_APP_CONVERSATION_SERVICE_API_BASE_URL || `${window.location.protocol}//${hostname}${port}/api/v1/`;

        const timestamp = Date.now();
        const date = new Date(timestamp);

        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        const formattedTime = `${hours}:${minutes}:${seconds}`;
    
    
        axios.post(baseUrl + 'getLLMResponse', {            
            question: "ORIGINAL MESSAGE: "+originalMessage.text+"\nUSER CORRECTIONS: "+message,
            mode: iaRole,
            user_id: userId
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
            })
        .then(response => {
            console.log(response);
            addMessage({ sender: `AI-CFM [Regenerated] (${formattedTime}) `, text: response.data.message, showSaveButton: response.data.showSaveButton, dataToSave: response.data.dataToSave, id: Date.now(), parentId: originalMessage.id});
            setIsLoading(false);            
        })
        .catch(error => {
            setIsLoading(false);
            console.error('Error:', error);
        });

        setMessage('');
    };    

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSendMessage();
        }
    };

    return (
        <div style={{ marginTop: '5px', left: files.length > 0 ? '240px' : '0', right: '10px', width: files.length > 0 ? 'calc(100% - 50px)' : 'calc(100% - 20px)', marginLeft: '10px' }}>
            <InputGroup className="mb-3">
                <FormControl
                    placeholder="Type a correction to regenerate this message"
                    aria-label="Type a correction to regenerate this message"
                    value={message}
                    onChange={handleMessageChange}
                    onKeyPress={handleKeyPress}
                    disabled={isLoading}
                />
                <InputGroup.Text onClick={!isLoading ? handleSendMessage : undefined} style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}>
                    <FontAwesomeIcon icon={faPaperPlane} />
                </InputGroup.Text>
            </InputGroup>
        </div>
    );
};

export default ChatRegenerateInput;