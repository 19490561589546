import React from 'react';
// import axios from 'axios'; // Will use to query microservices for their versions, if needed
import { useEnvContext } from './useEnvContext';

const Footer = () => {

    const env = useEnvContext();

    const clusterName = () => {
        // TODO: Query microservices for cluster
        if (env.clusterName === 'prod') {
            return '';
        }
        return `${env.clusterName}-`;
    }

    const envName = () =>{
        // TODO: Query microservices for environment
        if (env.envName === 'freemium') { // Change this to what our freemium environment is called
            return '';
        }
        return `${env.envName}-`;
    }

    const platformVersion = () => {
        if (env.platformVersion) return `${env.platformVersion}`;
        if (process.env.REACT_APP_PLATFORM_VERSION) return `${process.env.REACT_APP_PLATFORM_VERSION}`;
        return '0.0.0';
    }

    return (
        <div style={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
            height: '30',
            overflow: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '2',
            boxSizing: 'border-box',
            fontSize: '0.8em',
            color: '#333'
        }}>
            <span>SmartStream &copy; 2024 - Platform Version: {clusterName()}{envName()}{platformVersion()}</span>
        </div>
    )
}

export default Footer;