import React, { useState, useEffect, useRef, useContext } from 'react';
import './App.css';
import Chat from './Components/Chat';
import ChatInput from './Components/ChatInput';
import Sidebar from './Components/Sidebar';
import FileProvider from './FileProvider';
import Footer from './Components/Footer';
import ChatMode from './Components/ChatMode';
import Modal from './Components/Modal';
import Login from './Components/Login';
import RequiredFilesModal from './Components/RequiredFilesModal';
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import EnvironmentContext from './Components/EnvironmentContext';
import AuthConfig from "./Components/AuthConfig";
import TokenChecker from './Components/TokenChecker';

function App() {
    const initialMessage = { sender: 'AI-CFM', text: 'Hi! How can I help you?', id: Date.now() };
    const [chats, setChats] = useState([{ id: 'Workstream', messages: [initialMessage] },{ id: 'epics', messages: [initialMessage] },{ id: 'Unknown', messages: [initialMessage] }]);
    const [activeChatId, setActiveChatId] = useState('Workstream');
    const [isLoading, setIsLoading] = useState(false);
    const [iaRole, setIARole] = useState('Workstream');
    const [showModal, setShowModal] = useState(false);
    const [showRequiredFilesModal, setShowRequiredFilesModal] = useState(false);
    const [userName, setUserName] = useState('User');
    const [allRequiredFilesPresent, setAllRequiredFilesPresent] = useState(false);
    const [token, setToken] = useState(null);
    const modalRef = useRef(null);
    const { msalConfig, loginRequest, msalInstance } = AuthConfig();

    // set constants to initialize during build time
    const hostname = window.location.hostname;
    const port = window.location.port ? `:${window.location.port}` : '';
    const baseUrl = `${window.location.protocol}//${hostname}${port}`;
    const baseApiUrl = `${baseUrl}/api/v1/`;
    const logoutUrl = `${window.location.protocol}//${hostname}${port}/`;

    const handleTriggerloadFileRequirements = async () => {
        if (modalRef.current) {
          await modalRef.current.loadFileRequirements();
        }
      };

    const addMessage = (newMessage) => {
        setChats(prevChats => {
            return prevChats.map(chat => {
                if (chat.id === activeChatId) {
                    return { ...chat, messages: [...chat.messages, newMessage] };
                }
                return chat;
            });
        });
    };

    const addNewChat = (newChatId) => {        
        setChats([...chats, { id: newChatId, messages: [initialMessage] }]);
        setActiveChatId(newChatId);
    };

    const switchChat = (chatId) => {
        setActiveChatId(chatId);
    };

    const activeChat = chats.find(chat => chat.id === activeChatId) || { messages: [] };

    const changeIARole = (role) => {
        setIARole(role);
        switchChat(role);
    };

    const handleSettingsClick = () => {
        setShowModal(true);
    };

    const handleLogin = () => {
        msalInstance.loginRedirect(loginRequest).catch(e => {
            console.error(e);
        });
    };

    const handleLogout = () => {
        if (msalInstance.getAllAccounts().length > 0) {
            const account = msalInstance.getAllAccounts()[0];
            msalInstance.logoutRedirect({
                account: account,
                postLogoutRedirectUri: logoutUrl
            }).catch(e => {
                console.error(e);
            });
            localStorage.removeItem('userName')
        }
    };

    const handleResultsUpdate = (filesPresent) => {
        setAllRequiredFilesPresent(filesPresent);
      };

    return (
            <MsalProvider instance={msalInstance}>
                <AuthenticatedTemplate>
                    <TokenChecker>
                        <FileProvider>
                            <div className="app-container">
                                <Sidebar setShowRequiredFilesModal={setShowRequiredFilesModal} />
                                <div className="chat-container">
                                    <ChatMode changeIARole={changeIARole} handleSettingsClick={handleSettingsClick} isLoading = {isLoading}/>
                                    <Chat addMessage={addMessage} setIsLoading={setIsLoading} messages={activeChat.messages} isLoading={isLoading} iaRole={iaRole} userName={userName}/>
                                    <ChatInput addMessage={addMessage} setIsLoading={setIsLoading} isLoading={isLoading} iaRole={iaRole} userName={userName} allRequiredFilesPresent={allRequiredFilesPresent} handleTriggerloadFileRequirements={handleTriggerloadFileRequirements}/>
                                </div>
                            </div>
                            <Footer />
                            <Modal 
                                    showModal={showModal} 
                                    setShowModal={setShowModal}
                                    handleLogout={handleLogout}
                                    baseApiUrl={baseApiUrl}
                                />
                            <RequiredFilesModal
                                ref={modalRef}
                                showModal={showRequiredFilesModal}
                                setShowModal={setShowRequiredFilesModal}
                                onResultsUpdate={handleResultsUpdate}
                            />
                        </FileProvider>
                    </TokenChecker>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                        <Login handleLogin = {handleLogin} baseApiUrl={baseApiUrl} setUserName={setUserName} />
                </UnauthenticatedTemplate>
            </MsalProvider>
    );
}

export default App;
