import React, { useState, useRef, useContext, useEffect } from 'react';
import axios from 'axios';
import RequiredFileUploadStatus from './RequiredFileUploadStatus';
import FileContext from '../FileContext';
import{ jwtDecode } from 'jwt-decode';
import { useToken } from './useToken';

const RequiredDocumentUpload = ({ onFileSelect, uploadButtonText, fileType, fileDescription, onFileVerification }) => {
  const { addFile } = useContext(FileContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [showUploadStatus, setShowUploadStatus] = useState(false);
  const fileInput = useRef(null);
  const hostname = window.location.hostname;
  const port = window.location.port ? `:${window.location.port}` : '';
  const baseUrl = process.env.REACT_APP_FILE_SERVICE_API_BASE_URL || `${window.location.protocol}//${hostname}${port}/api/v1/`;
  const baseCFMUrl = process.env.REACT_APP_CFM_SERVICE_API_BASE_URL || `https://${hostname}/api/v1/`;
  const tokenData = useToken();
  const token = tokenData.token;
  const userId = tokenData.userId.toString();

  const handleFileInput = e => {
    if (e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (selectedFile) {
      uploadFile(selectedFile).catch(err => console.log(err));
    }
  }, [selectedFile]);

  const handleClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleCloseUploadStatus = () => {
    setShowUploadStatus(false);
  };

  const uploadFile = async (file) => {
    setShowUploadStatus(true);
    setStatusMessage('Loading');
    const formData = new FormData();
    formData.append('file', file);
    formData.append('userID', userId);
 

    try {
      const response = await axios.post(`${baseUrl}files/upload/`, formData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

      addFile(response.data);
      if (onFileSelect) {
        onFileSelect(response.data['filename']);
      }

      setStatusMessage('Verifying');

      const evaluationData = {
        file_name: response.data['filename'],
        file_description: fileDescription,
        file_type: fileType,
      };

      const cfmResponse = await axios.post(`${baseCFMUrl}cfm/evaluateRequiredDocument`, evaluationData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      
      onFileVerification(cfmResponse.data['result'])

    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setShowUploadStatus(false);
      setStatusMessage('');
    }
    setSelectedFile(null);
    fileInput.current.value = '';
  };

  return (
    <div>
      <input type="file" ref={fileInput} onChange={handleFileInput} style={{ display: 'none' }} accept=".pdf, .docx"/>
      <span style={{ cursor: 'pointer' }} onClick={handleClick}>{uploadButtonText}</span>
      <RequiredFileUploadStatus
        selectedFile={selectedFile}
        statusMessage={statusMessage}
        show={showUploadStatus}
      />
    </div>
  );
};

export default RequiredDocumentUpload;
