import React, { useState, useEffect } from 'react';
import RequiredDocumentUpload from './RequiredDocumentUpload';
import { InputGroup, FormControl } from 'react-bootstrap';

const RequiredFileInput = ({ file, handleUpload, initialUploadedFileName = null }) => {
    const [uploadedFileName, setUploadedFileName] = useState(initialUploadedFileName);
    const [isVerified, setIsVerified] = useState(null);
    const [verificationResult, setVerificationResult] = useState(null);

    useEffect(() => {
        if (initialUploadedFileName != null) {
            setUploadedFileName(initialUploadedFileName);
        } else {
            setUploadedFileName(null);
            setIsVerified(null);
        }
    }, [initialUploadedFileName]);

    const handleFileSelect = (fileName) => {
        setUploadedFileName(fileName);
        handleUpload(fileName);
    };

    const handleVerification = (verificationResult) => {
        setVerificationResult(verificationResult)
        if (verificationResult === 'yes') {
            setIsVerified(true);
        } else {
            setIsVerified(false);
            console.log("File Not Valid Response: ", verificationResult);
        }
    };

    return (
        <div className="file-input">
            <h3>{file.type}</h3>
            <p>{file.description}</p>
            <InputGroup className="mb-3">
                <FormControl
                    placeholder="No file uploaded"
                    value={uploadedFileName || ""}
                    readOnly={true}
                    isInvalid={!isVerified}
                    style={isVerified === null ? null : isVerified ? ({ backgroundColor: '#f4fff2' }) : ({})}
                />
                <InputGroup.Text>
                    <RequiredDocumentUpload
                        uploadButtonText={uploadedFileName ? 'Change' : 'Upload'}
                        onFileSelect={handleFileSelect}
                        onFileVerification={handleVerification}
                        fileType={file.type}
                        fileDescription={file.description}
                    />
                </InputGroup.Text>
            </InputGroup>
            {isVerified === null ? null : isVerified ? (
                <span className="verified">Verified</span>
            ) : (
                <span className="not-verified">File not valid</span>
            )}
        </div>
    );
};

export default RequiredFileInput;
