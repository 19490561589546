import { PublicClientApplication } from "@azure/msal-browser";
import { EnvironmentContextObject } from './EnvironmentContext';
import { useContext, useMemo } from 'react';

const hostname = window.location.hostname;
const tenantName = "aicfm";
const b2cPolicy = "B2C_1_signin_only";
const port = window.location.port ? `:${window.location.port}` : '';
const redirectUri = `${window.location.protocol}//${hostname}${port}/` || "https://cfm-devint.calavista.ai";

let msalInstance;

const AuthConfig = () => {
    const env = useContext(EnvironmentContextObject);
    const clientId = env.auth_client_id;

    const msalConfig = useMemo(() => ({
        auth: {
            clientId: clientId,
            authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${b2cPolicy}`,
            redirectUri: redirectUri,
            postLogoutRedirectUri: redirectUri,
            knownAuthorities: [`${tenantName}.b2clogin.com`],
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: true,
        },
        system: {
            allowNativeBroker: true,
        },
    }), [clientId]);

    if (!msalInstance && clientId) {
        msalInstance = new PublicClientApplication(msalConfig);
    }

    const loginRequest = useMemo(() => ({
        scopes: ["openid", "profile", "email", 'offline_access', clientId],
    }), [clientId]);

    return { msalConfig, loginRequest, msalInstance };
};

export default AuthConfig;
